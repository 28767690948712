const baseUrl = 'https://api.wefishtheworld.com';

export default {
  map: {
    defaultCenter: {
      // sundsvall
      lat: 62.3908,
      lng: 17.3069,
    },
    defaultZoomLevel: {
      desktop: 5,
      mobile: 2,
    },
  },
  api: {
    baseUrl,
    graphql: `${baseUrl}/graphql`,
  },
  ad: {
    imageUrl: 'https://source.unsplash.com/random',
    targetUrl: 'https://aftonbladet.se',
  },
  footer: {
    affiliate: {
      title: 'Vill du också synas på sajten?',
      link: 'https://test.com',
    },
    contact: {
      title: 'Kontakt',
      email: 'mail@gmail.com',
    },
    company: {
      title: 'We fish the world AB',
      address: `Brunnsgatan 6
      123 51 Stockholm
      `,
    },
  },
};
